import { IconButton, Slide, Snackbar, Alert } from "@mui/material";
import styles from "./errormodal.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Errmodal = ({ errorMsg, seterrorMsg }) => {
  const { t } = useTranslation();
  const [slide, setSlide] = useState(false);

  useEffect(() => {
    if (errorMsg) {
      setSlide(true);
    }
  }, [errorMsg]);

  const close = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        className=" text-danger"
      ></IconButton>
    </>
  );

  return (
    <div className={styles.errmsg}>
      <Slide direction="right" in={slide}>
        <Snackbar
          open={!!errorMsg}
          autoHideDuration={2000}
          onClose={() => {
            seterrorMsg("");
            setSlide(false);
          }}
          action={close}
          style={{ minWidth: "20vw" }}
        >
          <Alert
            severity="error"
            variant="filled"
            sx={{
              width: "100%",
              ".MuiAlert-icon": { color: "white !important" },
            }}
          >
            {t(errorMsg)}
          </Alert>
        </Snackbar>
      </Slide>
    </div>
  );
};

export { Errmodal };
