import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import styles from "./successmodal.module.css";
import { useTranslation } from "react-i18next";
import { IconButton, Slide, Snackbar } from "@mui/material";

function hasEnglishLetters(text) {
  var englishLettersRegex = /[a-zA-Z]/;

  return englishLettersRegex.test(text);
}

const Successmodal = ({ successMsg, setSuccessMsg }) => {
  const { i18n } = useTranslation();
  const [slide, setSlide] = useState(false);

  useEffect(() => {
    if (successMsg) {
      setSlide(true);
    }
  }, [successMsg]);

  //

  const close = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        className=" text-danger"
      ></IconButton>
    </>
  );
  return (
    <div className={styles.suc_msg}>
      <Slide direction="right" in={slide}>
        <Snackbar
          open={!!successMsg}
          autoHideDuration={2000}
          onClose={() => {
            setSuccessMsg("");
            setSlide(false);
          }}
          action={close}
          style={{ minWidth: "20vw" }}
        >
          <Alert
            severity="success"
            variant="filled"
            sx={{
              width: "100%",
              ".MuiAlert-icon": { color: "white !important" },
            }}
          >
            {i18n.language === "en"
              ? successMsg
              : i18n.language === "ar" && !hasEnglishLetters(successMsg)
              ? successMsg
              : "تم"}
          </Alert>
        </Snackbar>
      </Slide>
    </div>
  );
};

export { Successmodal };
