import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotificationsSnackBar = ({ notificationsRedirectRoute, open, setMsgBody, msgBody }) => {
  const Navigate = useNavigate()



  const handleClose = () => {
    setMsgBody();
  };
  useEffect(() => {
    setTimeout(() => {
      if (msgBody) handleClose()
    }, 5000);
  }, [msgBody]);
  return (
    <div style={{ cursor: 'pointer' }}
      onClick={() => {
        Navigate(notificationsRedirectRoute)
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={!!msgBody}
        onClose={handleClose}
        message={msgBody}
        key={'bottom' + 'left'}
      />
    </div>
  );
}

export { NotificationsSnackBar };
