// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  updateDoc,
  doc,
  setDoc,
  limit,
  startAfter,
  getDocs,
  endBefore,
  getDoc,
} from "firebase/firestore";
// import { GoogleAuth } from "google-auth-library";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

let lastMsg;
export const removeLastMsg = () => {
  lastMsg = undefined;
};

//     apiKey: process.env.REACT_APP_APIKEy,
//     authDomain:  process.env.REACT_APP_AUTHDOMAIN,
//     projectId:  process.env.REACT_APP_PROJECTID,
//     storageBucket:  process.env.REACT_APP_STORAGEBUCKET,
//     messagingSenderId:  process.env.REACT_APP_messagingSenderId,
//     appId:  process.env.REACT_APP_APPID,
//     measurementId: process.env.REACT_APP_MEASUREMENTID
// });

const firebaseConfig = {
  apiKey: "AIzaSyCN3fe6hJjK4FjOAX5Hqn_GYEOhqW77JF4",
  authDomain: "green-cadb3.firebaseapp.com",
  projectId: "green-cadb3",
  storageBucket: "green-cadb3.appspot.com",
  messagingSenderId: "809334948932",
  appId: "1:809334948932:web:f0dbafabc5043debe7e811",
  measurementId: "G-6KTJVMVWJ9",
};

const serviceAccount = {
  type: "service_account",
  project_id: "green-cadb3",
  private_key_id: "0b63ec6ef3365208eb5589c8ddd7014330c132fe",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCWi4msooejljJD\nZEZXOR+64aUrLQRQHDiSqQh41r2Ee7+z2dckFLmRDldRIXLivnpIe8sF7L80N+6l\ng0m5ykjR9/gpyiHckumhxsxdag2IGXICWYJdRnfnhL5F/mBFSD6quo3TXDbTByYZ\nPU/CF1KLF83aiWTdcClEwPnJ73fBlhjA0t2xSIR4K7n4X0+cLJWjmRzSZDS9CzWE\naaSngXzPWKyiIAR5bugPIdrQFZAq/t14i1NzeHivmF3AYz2yLRWQDGiSFyeE1dnl\n8Yiy52OxquAHhiIhvM6nJvkT+f0h/PUBKx+yH4XrkXrWUy7xiT1WSXEuTZtCkrOa\n53KqOvPdAgMBAAECggEAATTDUamWkymp1YmbvKkL48ED0ltXzlYONjKWmZ8Elq6i\ndbaxIdbs46vl6KIZ6iPqMhwidMP5MNov3VUL4jhzDa6z9TlO2V1FEHbn4bDSb0PC\ns5JzdKX2fktLkrumfeliNOyDXV730M9V6Gpp8gDbf2Idfpcc3wn28q1YATHjAjui\noGz0BALVAdBwaytII0Ni9vASeJda5awdmg9f1/jDTzcRcDeR49RxAjPh7EVfrSqW\nNokura9vjvAEpT3qg9FXRHNaaCfzWSxuY7Ckpmikc3dqmlqg1Ywtn30dQ/JQnEGJ\nuSCPskP2wYLLoBe7AUwlVYTB64as8uEc1+AkzEhUcQKBgQDUJw5vz3m+UwIXsPUR\niyC+k6y3DKyS9212mmCHJ0jjgKhPkA7ZkUh0Nj/eMdE6PqLQogkIw4aNQYYICv8e\n6qpLf+tt8MwAfrN3+POkaPFovgJH4BU3n9npbwHzSNoLZjhdQJyIf8BHSX86CoMn\nuZFWYPe6RFKy/LfXCDovpDZ4iQKBgQC1qNfMTEeuj/Bkyu70eeNzVPpBo8fc3NLW\nlXs50+UyMpP7lJ9uUkBRdSWkrEMBxXFKgnRfckQlRLn9jHyXEUIQWOg/NcXvn+E1\nF3m7CXyJNthQpdM0Xo8IKVkDpqdMOVNTJC4ViLqSZgxj2OJ7I0EivZVhgW55G5ii\nLc+T6hUjtQKBgDdTdCVegw5KUXpiMJcBpGlu05TtHjg5hHdwrQmyCgUicUJGfEjq\n5W1BFIjajwjUettT1m1NqFJAKzb/vyaN5hlIY9pI+LqMUDtSVh+jKom5O5kI7B+U\ndVy2EVkjv5FHeB2BkxDZ2Af/N8moIioC788J/LC7zqFU3JCcWahA99+ZAoGBAJuN\n3FRF0W/qM6irmVjhcuIUl0NT8tXuheBYGi/XgcCtTMi2aJs4dXkLPlO3ap1+q9do\nxCvjFfVv13AFN77IeXC0IRPXgQoDNv6WfpeptOlufGb6yFNk0Ufk2CNnNoTv5akh\nJxErhs/L8LojQzS8aI9OFjdrdOThu57MaZ64aKetAoGBALnTSG8rdWWEnZuZrpjb\nh1GsteY6S0YvzCjLJmW/XI559ccEvmGQnVtREqERpf5bZ0NtAIwekr2j9YVqbW2d\ndFyC8nlYj6brp2LHd9dsbVPeFBPj7Ji90o3WdNbtg6xYfPSlUgr07UiJiXvNx8/V\n+U+xVynhJ0L22/47Hrjzdy6e\n-----END PRIVATE KEY-----\n",
  client_email: "firebase-adminsdk-6mox1@green-cadb3.iam.gserviceaccount.com",
  client_id: "104985964270571509060",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-6mox1%40green-cadb3.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// ------------------------------------------------------------------------------------------------
export const messaging = getMessaging(app);
// ------------------------------------------------------------------------------------------------

export const requestToken = async (setFcmToken = () => { }) => {
  let currentToken = "";
  // requestPermission()
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      currentToken = await getToken(messaging, {
        vapidKey:
          "BMJDC81lhHQQsbiurzGoJvR-3ANWlGLEMMHN7-azuIbjuFP0GMj2YwEsg41x4IZ7jnpogehc8wfv6hXQFfpa6r0",
      });
      if (currentToken) {
        console.log("Notifications  Allowed");

        setFcmToken(currentToken);
      } else {
        console.log("Notifications Not Allowed");
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const getFirebaseDoc = async (collictionName, id) => {
  const docRef = doc(db, collictionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
// ------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------=

function getMessages(firebaseCollection, customer, msgs, callback) {
  let limitMsgs = process.env.REACT_APP_firebase_msgs_page_size;
  let q = !!msgs?.length
    ? query(
      collection(db, firebaseCollection, customer?.id, "messages"),
      orderBy("timestamp", "desc"),
      endBefore(msgs[msgs.length - 1])
    )
    : query(
      collection(db, firebaseCollection, customer.id, "messages"),
      orderBy("timestamp", "desc"),
      limit(limitMsgs)
    );

  let unsubscribe = onSnapshot(q, (querySnapshot) => {
    let messages = [];

    querySnapshot.docChanges().forEach(async (change, i) => {
      if (change.type === "added") {
        messages.push(change.doc);
      }
    });
    callback(messages.reverse(), unsubscribe);
  });
}
// ------------------------------------------------------------------------------------------------
async function readMessages(firebaseCollection, user) {
  try {
    await updateDoc(doc(db, firebaseCollection, user.id), {
      is_read: true,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function AddFirebaseDoc(firebaseCollection, userId, callback = () => { }) {
  try {
    await setDoc(doc(db, firebaseCollection, userId), {
      content: "",
      // 'timestamp': serverTimestamp(),
      sender: "0000",
      receiver: userId,
      isRead: true,
      type: 0,
      attachedFile: "",
    });
    callback();
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function updateUserTo(firebaseCollection, user, text) {
  try {
    await setDoc(doc(db, firebaseCollection, user.id), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------
async function sendMessage(firebaseCollection, user, text) {
  try {
    await setDoc(doc(db, firebaseCollection, user.id), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
    await addDoc(collection(db, firebaseCollection, user?.id, "messages"), {
      sender: "0000",
      // 'text_message': text,
      receiver: user.id,
      is_read: false,
      timestamp: serverTimestamp(),
      type: 0,
      attachedFile: "",
      content: text,
    });
  } catch (error) {
    console.log(error);
  }
}
// ------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------=
const listenOnDoc = (id, setDoc) => {
  const unsub = onSnapshot(
    doc(db, process.env.REACT_APP_FIREBASE_DRIVERS_COLLECTION, id),
    (doc) => {
      // console.log("Current data: ", doc.data());
      setDoc(doc.data());
    }
  );
  return unsub;
};
// ------------------------------------------------------------------------------------------------
const paginateMsgs = async (
  firebaseCollection,
  lastVisibleMsg,
  customerId,
  callback
) => {
  const messages = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, firebaseCollection, customerId, "messages"),
      orderBy("timestamp", "desc"),
      limit(process.env.REACT_APP_firebase_msgs_page_size),
      startAfter(lastVisibleMsg)
    )
  );
  let counter = 0;
  if (!querySnapshot.docs.length) {
    lastMsg = undefined;
  }
  querySnapshot.forEach((doc, i) => {
    counter++;

    if (counter === querySnapshot.docs.length) {
      lastMsg = doc;
    }
    // doc.data() is never undefined for query doc snapshots
    messages.push(doc);
    if (counter === querySnapshot.docs.length) {
      counter = 0;
    }
  });
  callback(messages.reverse(), lastMsg);
};
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const paginateMsgsAsc = async (
  firebaseCollection,
  lastVisibleMsg,
  customerId,
  callback
) => {
  let messages = [];
  const querySnapshot = await getDocs(
    query(
      collection(db, firebaseCollection, customerId, "messages"),
      orderBy("timestamp", "desc"),
      endBefore(lastVisibleMsg)
    )
  );
  let counter = 0;
  querySnapshot.forEach((doc, i) => {
    counter++;
    if (counter === querySnapshot.docs.length) {
      lastMsg = doc;
    }
    // doc.data() is never undefined for query doc snapshots
    messages.push({ ...doc.data(), id: doc.id });
    if (counter === querySnapshot.docs.length) {
      counter = 0;
    }
  });
  callback(messages, lastMsg);
  messages = [];
};
// ------------------------------------------------------------------------------------------------
let prevFirebaseCollection;
async function getCustomers(firebaseCollection, setCustomers, callback) {
  return onSnapshot(
    query(
      collection(db, firebaseCollection),
      orderBy("timestamp", "desc"),
      limit(15)
      // process.env.REACT_APP_firebase_msgs_page_size
    ),
    (querySnapshot) => {
      let customers = [];
      let modifiedCustomers = [];
      const res = querySnapshot.docChanges().forEach((change, i) => {
        if (change.type === "added") {
          // console.log(change.doc.data().content);
          customers.push(change.doc);
        }
        if (change.type === "modified") {
          modifiedCustomers.push(change.doc);
        }
      });
      setCustomers((prev) => {
        if (prev[firebaseCollection]) {
          let prevCustemersAndAdded = [
            ...prev[firebaseCollection],
            ...customers,
          ];
          let customersWithoutmodefied = prevCustemersAndAdded.filter(
            (c) => !modifiedCustomers.find((el) => el.id === c.id)
          );

          prev[firebaseCollection] = [
            ...modifiedCustomers,
            ...customersWithoutmodefied,
          ];
          return { ...prev };
        }
        prev[firebaseCollection] = customers;
        return { ...prev };
      });
      setTimeout(() => {
        prevFirebaseCollection = firebaseCollection;
      }, 0);
      callback(customers);
    }
  );
}

// ------------------------------------------------------------------------------------------------
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
// ------------------------------------------------------------------------------------------------
const paginateCustomers = (customers, firebaseCollection, callback) => {
  return onSnapshot(
    query(
      collection(db, firebaseCollection),
      orderBy("timestamp", "desc"),
      limit(process.env.REACT_APP_firebase_msgs_page_size),
      startAfter(customers[customers?.length - 1])
    ),
    (querySnapshot) => {
      const customers = querySnapshot.docs;
      callback(customers);
    }
  );
};
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const getcutomerObj = async (customer) => {
  const docRef = doc(db, "users-staging", customer?.id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    // console.log("No such document!");
  }
  return docSnap;
};

const getCustomerProfile = async (id, setCustomerProfile) => {
  const docRef = doc(db, process.env.REACT_APP_FIREBASE_users_collection, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    setCustomerProfile(docSnap.data());
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    setCustomerProfile();
  }
};

export {
  AddFirebaseDoc,
  updateUserTo,
  listenOnDoc,
  getCustomerProfile,
  getFirebaseDoc,
  paginateMsgsAsc,
  db,
  sendMessage,
  getMessages,
  readMessages,
  paginateMsgs,
  getCustomers,
  paginateCustomers,
  getcutomerObj,
};
