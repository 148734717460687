import styles from "./drawer.module.css";
import { useContext, useState } from "react";
import { Drawerlink } from "./drawerlink";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import GroupIcon from "@mui/icons-material/Group";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../Components/contexts/authContext/authContext";
import logo from "../../assets/Image/drawer-logo.svg";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import RouteIcon from "@mui/icons-material/Route";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import { useEffect } from "react";
import DiscountIcon from "@mui/icons-material/Discount";
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import NotificationsIcon from "@mui/icons-material/Notifications";
// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import ModeCommentIcon from '@mui/icons-material/ModeComment';
import chat from "../../assets/Image/chat.svg";
import manual_dispatch from "../../assets/Image/manual_dis.svg";
import garage_icon from "../../assets/Image/garage_icon.svg";
import { useTranslation } from "react-i18next";
import { Avatar, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { UserInfoModal } from "./user-info-modal/user-info-modal";

const Drawer = ({ isDrawerOpen, setisDrawerOpen, children }) => {
  const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
  const { t, i18n } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [drawerWidth, setDrawerWidth] = useState(60);
  const [isMultiLinksOverFlowHidden, setIsMultiLinksOverFlowHidden] =
    useState(false);
  const [subLinksArr, setSubLinksArr] = useState([]);
  const [pos, setPos] = useState({});
  const { currentUser } = useContext(AuthContext);
  const Navigate = useNavigate();
  const location = useLocation().pathname;
  // ----------------------open close drawer-------------------------------------------
  const checkauth = (path) => {
    const isAuth =
      (currentUser?.permissions?.includes("view_dashboard") && path === "/") ||
      (currentUser?.permissions?.includes("view_garage") &&
        path === "/garages") ||
      (currentUser?.permissions?.includes("view_notification_history") &&
        path === "/notification-history") ||
      // path === "/drawing-map" ||
      (currentUser?.permissions?.includes("view_live_view") &&
        path === "/map") ||
      (currentUser?.permissions?.includes("view_drivers_screen") &&
        (path === "/drivers?page=1" || path === "/driverdetails")) ||
      (currentUser?.permissions?.includes("view_driver_wallet") &&
        (path === "/driverwallet?page=1" ||
          path === "/driver-transaction-details")) ||
      (currentUser?.permissions?.includes("view_driver_groups") &&
        (path === "/driversgroups?page=1" ||
          path === "/driversgroupdetails")) ||
      (currentUser?.permissions?.includes("view_passengers_screen") &&
        (path === "/passengers?page=1" || path === "/passengerdetails")) ||
      (currentUser?.permissions?.includes("view_passengers_wallet") &&
        (path === "/pwallet?page=1" ||
          path === "/passenger-transaction-details")) ||
      (currentUser?.permissions?.includes("view_passengers_groups") &&
        (path === "/passengersgroups?page=1" ||
          path === "/passengersgroupdetails")) ||
      (currentUser?.permissions?.includes("view_trips") &&
        (path === "/trip?page=1&trip_type=0" ||
          path === "/garage-trip?page=1" ||
          path === "/tripdetails")) ||
      (currentUser?.permissions?.includes("view_scheduled_trips_calender") &&
        path === "/scheduletrips") ||
      (currentUser?.permissions?.includes("view_scheduled_trips_general") &&
        path === "/scheduledtrips?page=1") ||
      (currentUser?.permissions?.includes("view_manual_dispatch") &&
        path === "/manual-dispatch") ||
      (currentUser?.permissions?.includes("view_vehicles_management") &&
        path === "/cars?page=1") ||
      (currentUser?.permissions?.includes("view_notification_center") &&
        path === "/notifications-center?page=1") ||
      (currentUser?.permissions?.includes("view_promo_code_management") &&
        path === "/promocode?page=1") ||
      (currentUser?.permissions?.includes("view_chat_system") &&
        path === "/chat") ||
      (currentUser?.permissions?.includes("view_general_settings") &&
        path === "/general") ||
      (currentUser?.permissions?.includes("view_users_group") &&
        (path === "/usersgroups?page=1" || path === "/usersgroupdetails")) ||
      (currentUser?.permissions?.includes("view_archive") &&
        (path === "/passengersarchive?page=1" ||
          path === "/driversarchive?page=1" ||
          path === "/usersarchive?page=1")) ||
      (currentUser?.permissions?.includes("view_users_settings") &&
        path === "/users-management?page=1") ||
      path === "/not-auth";

    return isAuth;
  };
  // --------------------------------------------------------------------------------
  const filterLinks = (links) => {
    return links.filter((link) => {
      if (link.path) {
        return checkauth(link.path);
      } else {
        const subLinks = link.subLinks.filter((subLink) =>
          checkauth(subLink.path)
        );
        return subLinks.length > 0;
      }
    });
  };

  const filterSubLinks = (links) => {
    return links.map((link) => {
      if (link.subLinks instanceof Array) {
        const subLinks = link.subLinks.filter((subLink) =>
          checkauth(subLink.path)
        );
        return { ...link, subLinks };
      }
      return link;
    });
  };
  // --------------------------------------------------------------------------------
  const [links, setLinks] = useState([
    { icon: HomeIcon, path: "/", text: t("Dashboard") },
    { icon: MapIcon, path: "/map", text: t("Live view") },
    // { icon: MapIcon, path: "/drawing-map", text: t("Live view") },
    {
      icon: AirlineSeatReclineNormalIcon,
      text: t("Driver Management"),
      subLinks: [
        { path: "/drivers?page=1", text: t("Drivers") },
        { path: "/driverwallet?page=1", text: t("Driver Wallet") },
        { path: "/driversgroups?page=1", text: t("Drivers Groups") },
        { path: "/driversarchive?page=1", text: t("Drivers Archive") },
      ],
    },

    {
      icon: GroupIcon,
      text: t("Passengers"),
      subLinks: [
        { path: "/passengers?page=1", text: t("Passengers") },
        { path: "/pwallet?page=1", text: t("passengers Wallet") },
        { path: "/passengersgroups?page=1", text: t("Passengers Groups") },
        { path: "/passengersarchive?page=1", text: t("Passengers Archive") },
      ],
    },

    {
      icon: RouteIcon,
      path: "/trip?page=1&trip_type=0",
      text: t("Live Trips"),
    },
    {
      icon: DepartureBoardIcon,
      text: t("scheduled Trips"),
      subLinks: [
        { path: "/scheduletrips", text: t("Scheduled Trips Calender") },
        { path: "/scheduledtrips?page=1", text: t("scheduled Trips general") },
      ],
    },
    { icon: manual_dispatch, path: "/manual-dispatch", text: t("Create Trip") },
    {
      icon: DirectionsCarFilledIcon,
      path: "/cars?page=1",
      text: t("Vehicles Management"),
    },
    // { icon: DirectionsCarFilledIcon, path: '/cars?page=1', text: t('Vehicles Management') },
    // { icon: garage_icon, path: "/garages", text: t("Garages Management") },
    {
      icon: garage_icon,
      text: t("Garages Management"),
      subLinks: [
        { path: "/garages", text: t("Garages") },
        { path: "/garage-trip?page=1", text: t("Garage Trips") },
      ],
    },
    // { icon: RouteIcon, path: '/notification-history', text: t('Notification History') },
    {
      icon: NotificationsIcon,
      path: "/notifications-center?page=1",
      text: t("Notification Center"),
    },
    { icon: DiscountIcon, path: "/promocode?page=1", text: t("Promocode") },

    // { icon:i18n.language==='ar'? ChatBubbleIcon:ModeCommentIcon, path: '/chat', text: t('Chat') },
    { icon: chat, path: "/chat", text: t("Chat") },
    {
      icon: SettingsSuggestIcon,
      text: t("settings"),
      subLinks: [
        { path: "/general", text: t("general") },
        { path: "/users-management?page=1", text: t("Users Management") },
        { path: "/usersgroups?page=1", text: t("users groups") },
      ],
    },
  ]);
  const handleDrawerOpen = () => {
    if (!isDrawerOpen) {
      setDrawerWidth(240);
    } else {
      setDrawerWidth(60);
    }
    setisDrawerOpen(!isDrawerOpen);
  };
  // ----------------------------------------------------------------------------------
  useEffect(() => {
    setLinks((prev) => {
      let LinksArr = prev.filter((link) => {
        if (link.path) {
          return checkauth(link.path);
        } else {
          let sublink = link.subLinks.filter((subLink) => {
            return checkauth(subLink.path);
          });
          return sublink.length > 0;
        }
      });

      LinksArr.forEach((el, i) => {
        if (el.subLinks instanceof Array) {
          let subLinks = el.subLinks.filter((subLink) => {
            return checkauth(subLink.path);
          });
          LinksArr[i].subLinks = subLinks;
        }
      });

      return [...LinksArr];
    });

    // ----------------------------------------------------------------------------------
    return () => {
      setIsMultiLinksOverFlowHidden(false);
      setisDrawerOpen(false);
    };
  }, []);
  useEffect(() => {
    const filteredLinks = filterLinks(links);
    const filteredSubLinks = filterSubLinks(filteredLinks);

    setLinks(filteredSubLinks);

    return () => {
      setIsMultiLinksOverFlowHidden(false);
      setisDrawerOpen(false);
    };
  }, []);
  // ----------------------------------------------------------------------------------
  // handle render sublinks
  const handleMouseOverDrawer = (event) => {
    const target = event.target;
    const subLinksDiv = document.getElementById("sub_links_div");
    const parentLinkDiv = document.getElementById("hoveredDiv");
    const drawerWrapper = document.getElementById("drawer_wrapper");
    const linkWithoutSublinks = document.getElementsByClassName(
      "link_without_sublinks"
    );
    if (
      ((subLinksDiv &&
        (target.id === "sub_links_div" || subLinksDiv.contains(target))) ||
        (parentLinkDiv &&
          (target.id === "hoveredDiv" || parentLinkDiv.contains(target))) ||
        (drawerWrapper &&
          (target.id === "drawer_wrapper" ||
            drawerWrapper.contains(target)))) &&
      linkWithoutSublinks.length > 0 &&
      !target.classList.contains("link_without_sublinks")
    ) {
      return;
    } else {
      setSubLinksArr([]);
    }
  };
  // ----------------------------------------------------------------------------------
  const getHoveredDivCoord = (event) => {
    setTimeout(() => {
      const hoveredDiv = document.querySelector("#hoveredDiv");
      if (hoveredDiv) {
        const rect = hoveredDiv.getBoundingClientRect();
        const { top, right, bottom, height } = rect;
        // console.log(window.innerHeight, bottom,);
        // TODO ENHANCE CODE AND FIX HOVER AT PARENT ISSUE FROM ASYNC STATE

        setPos({
          top: window.innerHeight - bottom > 150 && top,
          bottom:
            window.innerHeight - bottom <= 150 &&
            window.innerHeight -
              bottom +
              (subLinksArr.length ? subLinksArr.length : 3) * height,
          right: right,
        });
      }
    }, 10);
  };
  // useEffect(() => {
  //     console.log(pos);
  // }, [pos]);

  return (
    <div
      style={{ width: "100vw", position: "relative", opacity: "1" }}
      onMouseOver={handleMouseOverDrawer}
    >
      <div
        id={"sub_links_div"}
        style={{
          position: "fixed",

          bottom: pos.bottom ? `${pos.bottom}px` : "auto",

          top: pos.top ? `${pos.top - 10}px` : "auto",
          left: i18n.language === "en" && (isDrawerOpen ? "240px" : "60px"),
          right: i18n.language === "ar" && (isDrawerOpen ? "240px" : "60px"),
          background: "red",
          zIndex: "5",
        }}
      >
        <div
          className={`${styles.subLink_wrapper} `}
          style={{
            right: i18n.language === "en" && "-180px",
            left: i18n.language === "ar" && "-180px",
          }}
        >
          {subLinksArr?.map((link, i) => {
            return (
              <p
                key={i}
                onClick={() => {
                  Navigate(link.path);
                }}
                style={{
                  fontSize: " 12px",
                  fontWeight: "bold",
                  borderBottom: subLinksArr?.length - 1 === i && "none",
                  paddingLeft: i18n.language === "en" && "15px",
                  paddingRight: i18n.language === "ar" && "15px",
                  borderRadius:
                    link.path === location.pathname && i === 0
                      ? "15px 15px 0 0 !important"
                      : i === subLinksArr?.length - 1 &&
                        "0 0 15px 15px !important",
                }}
                className={`${styles.subLink} ${
                  link.path === location.pathname && styles.selected_row
                }`}
              >
                {link.text}
              </p>
            );
          })}
        </div>
      </div>
      <div
        className={`text-light ${styles.drawer}`}
        id={"drawer_wrapper"}
        style={{
          width: `${drawerWidth}px`,
          right: i18n.language === "ar" && "0",
          left: i18n.language === "en" && "0",
        }}
      >
        {/* drawer header */}
        <div
          className={styles.drawer_header}
          style={{ transition: "all .3s ease-in" }}
        >
          <div className="h-100 d-flex align-items-center">
            <MenuIcon
              onClick={handleDrawerOpen}
              onMouseLeave={() => {
                setTimeout(() => {
                  setIsMultiLinksOverFlowHidden(false);
                }, 500);
              }}
              onMouseOver={() => setIsMultiLinksOverFlowHidden(true)}
              className={`${styles.open_close_icon} fs-3 `}
            />
          </div>
          <span className={styles.logo_wrapper}>
            {
              <img
                src={logo}
                className={`${styles.logo} my-2 `}
                style={{
                  transition: "all .3s ease-in",
                  opacity: isDrawerOpen ? 1 : 0,
                }}
                alt=""
              />
            }
          </span>
        </div>
        {/* drawer items wrapper */}
        <div
          className="w-100"
          style={{
            overflowY: "auto",
            maxHeight: "83%",
            overflowX: "hidden",
            height: "85%",
          }}
        >
          {/* drawer links */}
          {links.map((link, i) => {
            return (
              <Drawerlink
                getHoveredDivCoord={getHoveredDivCoord}
                setSubLinksArr={setSubLinksArr}
                setLinks={setLinks}
                isMultiLinksOverFlowHidden={isMultiLinksOverFlowHidden}
                linksLength={links.length}
                index={i}
                open={isDrawerOpen}
                key={i}
                path={link.path}
                Icon={link.icon}
                text={link.text}
                subLinks={link.subLinks}
              />
            );
          })}
          {/* logout */}
          <div
            onClick={logout}
            className={`${styles.nav_row} mb-4`}
            style={{ overflow: "hidden" }}
            onMouseOver={() => {
              setSubLinksArr([]);
            }}
          >
            <LogoutIcon className={`${styles.nav_icon}`} />

            <span className={styles.nav_text}>{t("Logout")}</span>
          </div>
        </div>
        {/* <div className={`${styles.nav_row} ${styles.profile_wrapper} d-flex justify-content-center align-items-center m-0`} style={{ cursor: 'initial', width: (isDrawerOpen) ? '240px' : '60px', transition: ' width .5s' }}>
                <Avatar src={`${process.env.REACT_APP_BASE_URL}${currentUser?.image}`} style={{ width: '35px', height: '35px' }} />
                {isDrawerOpen && <span className={`${styles.nav_text} d-flex ms-2`} >
                    {currentUser?.username}
                </span>}

            </div> */}
        <div
          onClick={(e) => {
            setOpenProfileInfoModal(true);
          }}
          // onMouseLeave={()=>{
          //     console.log('alo2');

          //     setOpenProfileInfoModal(false)
          //  }}
          className={`${styles.nav_row} ${styles.profile_wrapper} d-flex justify-content-center align-items-center`}
          style={{
            zIndex: "2",
            cursor: "initial",
            width: isDrawerOpen ? "240px" : "60px",
            transition: " width .5s",
          }}
        >
          <Tooltip title={currentUser?.full_name || ""}>
            <Avatar
              src={
                currentUser?.image
                  ? `${process.env.REACT_APP_BASE_URL}${currentUser?.image}`
                  : ""
              }
              style={{ width: "35px", height: "35px" }}
            />
          </Tooltip>
          {isDrawerOpen && (
            <span className={`${styles.nav_text} d-flex ms-2`}>
              {currentUser?.username}
            </span>
          )}
        </div>
      </div>
      {children}
      <UserInfoModal
        open={openProfileInfoModal}
        setOpen={setOpenProfileInfoModal}
      />
    </div>
  );
};

export { Drawer };
