import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from '../drawer/drawer';
import { useTranslation } from 'react-i18next';
import { messaging } from '../../Firebase/Firebase';
import { onMessage } from 'firebase/messaging';
import { NotificationsSnackBar } from '../../shared/notificationSnackBar/notificationsSnackBar';
let unsubscribeOnNotifications
const Layout = ({ children }) => {
    // hooks
    const { i18n } = useTranslation();
    const [renderDrawer, setRenderDrawer] = useState(false);
    const location = useLocation();
    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    const [notificationsRedirectRoute, setNotificationsRedirectRoute] = useState('');


    // effect
    useEffect(() => {
        if (location.pathname === '/login' && renderDrawer) {
            setRenderDrawer(false)
        } else if (location.pathname !== '/login' && !renderDrawer) {
            setRenderDrawer(true)
        }
    }, [location.pathname]);
    const [msgBody, setMsgBody] = useState();


    const Navigate = useNavigate()

    useEffect(() => {

        if (renderDrawer) {
            unsubscribeOnNotifications = onMessage(messaging, (payload) => {
                setNotificationsRedirectRoute(payload.data.type === "schedule" ? '/scheduledtrips?page=1' : '/chat');

                setMsgBody(
                    <div onClick={(e) => {
                        e.stopPropagation();
                        if (payload.data.type === "CHAT") {
                            Navigate('/chat')
                        } else {
                            Navigate('/scheduledtrips?page=1')
                        }
                    }}>
                        <div style={{ textAlign: 'start', fontSize: '1.2rem', fontWeight: '500' }}>
                            {payload.notification.title}
                        </div>
                        <div style={{
                            xtAlign: 'start',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '200px'
                        }} >
                            {payload.notification.body}
                        </div>
                    </div>
                )
            });
        }
        return () => {
            if (!renderDrawer && unsubscribeOnNotifications) unsubscribeOnNotifications();
        };
    }, [renderDrawer]);

    return (
        <>
            {renderDrawer && <> <Drawer isDrawerOpen={isDrawerOpen} setisDrawerOpen={setisDrawerOpen} >
                <main style={{
                    width: !isDrawerOpen ? 'calc(100% - 60px)' : 'calc(100% - 240px)',
                    marginLeft: i18n.language === "en" && 'auto', marginRight: i18n.language === 'ar' ?
                        'auto' : '0', transition: 'width .5s ', minHeight: 'calc(100vh)', background: 'var(--bg)'
                }}>
                    {children}
                </main>
                {msgBody && notificationsRedirectRoute && <NotificationsSnackBar setMsgBody={setMsgBody} msgBody={msgBody} notificationsRedirectRoute={notificationsRedirectRoute} />}
            </Drawer>

            </>}
            {/* {renderDrawer&& <MiniDrawer renderDrawer={renderDrawer}/>} */}
            {!renderDrawer && <><main style={{ width: '100vw', marginLeft: i18n.language === "en" && 'auto', marginRight: i18n.language === 'ar' && 'auto' }}>{children}</main></>}
        </>
    );
}

export { Layout };
